<template>
  <div class="c-app flex-row align-items-start" :style="{backgroundImage: 'url(' + require('@/assets/logo/bg.jpg') + ')', backgroundSize: 'contain'}">
    <div class="back">
      <div class="div-center">
        <div class="content_">
          <CCardGroup>
            <CCard class="p-4 radius">
              <CCardBody>
                <center>
                  <img src="@/assets/logo/logo.png" width="80%" alt="" />
                  <br >
                </center>
                <CForm class="mt-3">
                  
                  <CInput
                    placeholder="username"
                    v-model="form.username"
                    autocomplete="username"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    v-model="form.password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton
                        color="primary"
                        @click="submit()"
                        class="px-4 btn-block"
                        >Login</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
                <p class=" mt-3 text-center">version 1.0.0</p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "Login",

  data() {
    return {
      form: {},
    };
  },
  
  methods: {
    submit() {
      let loader = this.$loading.show();
      setTimeout(() => {
        this.$store
          .dispatch("auth/login", this.form)
          .then((resp) => {
            this.$router.replace({ name: "Home" });
            this.$toast.success("Login Berhasil");
            loader.hide();
          })
          .catch((e) => {
            loader.hide();
          });
      }, 2000);
    },
  },
};
</script>

<style >
.radius {
  border-radius: 15px !important;
}

.back {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.div-center {
  width: 500px;
  height: 400px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  display: table;
}

div.content_ {
  display: table-cell;
  vertical-align: middle;
}

.card {
  background-color: #ffffff9c !important;
}

.c-app {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  /* background-color: #006262; */
}
</style>